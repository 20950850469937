import React from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useStyles } from "./style.js";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import accountavatar from "../../Assets/Images/AccountAvatar.svg";
import myorders from "../../Assets/Images/myorders.svg";
import address from "../../Assets/Images/address.svg";
import myaccount from "../../Assets/Images/myaccount.svg";
import help from "../../Assets/Images/help.svg";
import Share from "../../Assets/Images/Share.svg";
import logouticon from "../../Assets/Images/logouticon.svg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloseIcon from "@mui/icons-material/Close";
import ReferFriends from "../AccountSettings/ReferFriends";
import MyAccount from "../AccountSettings/MyAccount";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Help from "../AccountSettings/Help";
import { db } from "../db.js";
import LoginOrSignupModal from "../Forms/LoginOrSignupModal.js";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import "./index.css";

const AccountPopOver = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [referopen, setReferOpen] = React.useState(false);
  const [helpopen, setHelpOpen] = React.useState(false);
  const [accountopen, setAccountOpen] = React.useState(false);
  const [deleteopen, setDeleteOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const Schema = Yup.object().shape({
    reason: Yup.string()
      .trim()
      .min(5, "Reason must be atleast 5 characters long")
      .required("This field is required"),
  });
  const setRoute = (e, title) => {
    e.persist();
    props.navigate(`/stores/Account/${title.replace(/ /g, "")}`);
  };
  const accountlist = [
    {
      title: "Account Settings",
      subtitle: "Manage your Account Settings",
      image: myaccount,
    },
    {
      title: "Addresses",
      subtitle: `${
        localStorage.getItem("location")
          ? localStorage.getItem("location").split(",", 1)
          : ""
      }`,
      image: address,
    },
    {
      title: "My Orders",
      subtitle: `${
        props.ActiveOrdersLength && props.ActiveOrdersLength
      } on-going orders`,
      image: myorders,
    },
    {
      title: "Refer Friends",
      subtitle: "Share with your friends",
      image: Share,
    },
    {
      title: "Help",
      subtitle: "Find how to explore the app",
      image: help,
    },
  ];
  const handleReferClose = () => {
    setReferOpen(false);
  };
  const handleHelpClose = () => {
    setHelpOpen(false);
  };
  const handleAccountClose = () => {
    setAccountOpen(false);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
 


  const logoutClick = (e) => {
    e.persist();
    e.preventDefault();
      localStorage.clear();
      localStorage.removeItem("location");
      props.clearSigninProps();
      props.resetsuggestions();
      setOpen(false);
      db.cart.clear();
      props.navigate("/");
   
  };
  const handleSubmit = (values) => {
    props.deleteaccount(localStorage.getItem("userid"), values.reason);
    localStorage.clear();
    db.cart.clear();
    setOpen(false);
    window.reload(true);
  };

  React.useEffect(
    () => {
      props.getActiveorderLength(localStorage.getItem("userid"));
      props.getUserDetails(localStorage.getItem("userid"));
    },
    []// eslint-disable-line react-hooks/exhaustive-deps
  );

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open === true) {
      props.getActiveorderLength(localStorage.getItem("userid"));
      props.getUserDetails(localStorage.getItem("userid"));
    }
    setState({ ...state, [anchor]: open });
  };
  
  const list = (anchor) => (
    <Box
      sx={{
        width: { xs: "100vw", md: 400 },
        backgroundColor: "rgb(246, 247, 248)",
      }}
      role="presentation"
      onClick={toggleDrawer("right", false)}
    >
      <div style={{ padding: "30px", display: "flex", width: "100%" }}>
        <span className={classes.floatLeft}>
          <span className={classes.leftarrow}>
            <KeyboardBackspaceIcon />
          </span>
          &nbsp;{`Hi ${props.Account && props.Account.firstName},`}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            textAlign: "right",
            color: "#FF0000",
            float: "right",
            width: "100%",
          }}
        >
          <span
            style={{ cursor: "pointer", width: "auto" }}
            className="delete"
            onClick={() => setDeleteOpen(true)}
          >
            {"Delete Account"}
          </span>
        </div>
      </div>
      <div>
        <Box className={classes.userBox}>
          <img
            src={accountavatar}
            alt="User.png"
            className={classes.accountavatar}
          />
          <div className={classes.userEmail}>
            {props.Account && props.Account.email && props.Account.email}
            <br />
            {props.Account &&
              props.Account.phoneNumber &&
              props.Account.phoneNumber}
          </div>
          <Box className={classes.accountlistBox} onClick={(e) => e.persist()}>
            <List style={{ padding: "40px" }} onClick={(e) => e.persist()}>
              {accountlist.map((text, index) =>
                text.title === "Refer Friends" ? (
                  <div
                    onClick={(e) => {
                      e.persist();
                      setReferOpen(true);
                    }}
                  >
                    <ListItem
                      button
                      key={index}
                      style={{
                        paddingBottom: "16px",
                        paddingTop: "16px",
                        display: "flex",
                      }}
                    >
                      <ListItemIcon>
                        <img
                          src={text.image}
                          alt="icon"
                          className={classes.accounticon}
                        />
                      </ListItemIcon>
                      <div className={classes.list}>
                        <span className={classes.title}>{`${text.title}`}</span>
                        <span className={classes.subtitle}>
                          {`${text.subtitle}`}
                        </span>
                      </div>
                    </ListItem>
                  </div>
                ) : text.title === "Account Settings" ? (
                  <div
                    onClick={(e) => {
                      e.persist();
                      setAccountOpen(true);
                    }}
                  >
                    <ListItem
                      button
                      key={index}
                      style={{
                        paddingBottom: "16px",
                        paddingTop: "16px",
                        display: "flex",
                      }}
                    >
                      <ListItemIcon>
                        <img
                          src={text.image}
                          alt="icon"
                          className={classes.accounticon}
                        />
                      </ListItemIcon>
                      <div className={classes.list}>
                        <span className={classes.title}>{`${text.title}`}</span>
                        <span className={classes.subtitle}>
                          {`${text.subtitle}`}
                        </span>
                      </div>
                    </ListItem>
                  </div>
                ) : (
                  <div
                    onClick={
                      text.title === "Help"
                        ? (e) => {
                            e.persist();
                            setHelpOpen(true);
                          }
                        : (e) => {
                            e.persist();
                            setRoute(e, text.title);
                          }
                    }
                  >
                    <ListItem
                      button
                      key={index}
                      style={{
                        paddingBottom: "16px",
                        paddingTop: "16px",
                        display: "flex",
                      }}
                    >
                      <ListItemIcon>
                        <img
                          src={text.image}
                          alt="icon"
                          className={classes.accounticon}
                        />
                      </ListItemIcon>
                      <div className={classes.list}>
                        <span className={classes.title}>{`${text.title}`}</span>
                        <span className={classes.subtitle}>
                          {`${text.subtitle}`}
                        </span>
                      </div>
                    </ListItem>
                  </div>
                )
              )}
              <div
                style={{ width: "100%" }}
                onClick={(e) => {
                  e.persist();
                  setOpen(true);
                }}
              >
                <ListItem
                  button
                  style={{
                    paddingBottom: "16px",
                    paddingTop: "16px",
                    display: "flex",
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={logouticon}
                      alt="icon"
                      className={classes.accounticon}
                    />
                  </ListItemIcon>
                  <div className={classes.list}>
                    <span className={classes.logouttitle}>{`Logout`}</span>
                  </div>
                </ListItem>
              </div>
            </List>
          </Box>
        </Box>
      </div>
    </Box>
  );
  React.useEffect(
    () => {
      props.getUserDetails(localStorage.getItem("userid"));
    },
    // eslint-disable-next-line
    [localStorage.getItem("userid")]
  );
  return (
    <div>
      <div
        className="notification-button"
        onClick={toggleDrawer("right", true)}
      >
        <PersonOutlineOutlinedIcon />
        <div>{"Account"}</div>
      </div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        PaperProps={{ style: { height: "100%", background: "#fff" } }}
        onClose={toggleDrawer("right", false)}
        sx={{
          borderTopLeftRadius: "14px",
          borderBottomLeftRadius: "14px",
        }}
      >
        {!localStorage.getItem("token") ||
        (props.isAccountFailed === true &&
          props.accounterror === "Unauthenticated!") ? (
          <Box
            sx={{
              width: { xs: "100vw", md: 400 },
              backgroundColor: "rgb(246, 247, 248)",
            }}
            role="presentation"
          >
            <div
              style={{
                paddingLeft: "30px",
                paddingTop: "30px",
                display: "flex",
                width: "100%",
                height: "12vh",
              }}
            >
              <span
                className={classes.floatLeft}
                onClick={toggleDrawer("right", false)}
              >
                <span className={classes.leftarrow}>
                  <KeyboardBackspaceIcon />
                </span>
              </span>
            </div>
            <div className={classes.notokendiv}>
              <div className={classes.loginpopupdiv}>
                <LoginOrSignupModal componentkey={"nologin"} {...props} />
              </div>
            </div>
          </Box>
        ) : (
          list("right")
        )}
      </Drawer>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "13px",
            backgroundColor: `#ffffff`,
            width: "450px",
            padding: "25px",
          },
        }}
        // fullScreen={fullScreen}
        maxWidth={"xs"}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          style={{
            fontSize: "18px",
            color: "#000000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          {`Are you sure you want to logout ?`}
        </DialogTitle>
        <DialogContent
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "80%",
              marginTop: "15px",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "8px",
              display: "flex",
            }}
          >
            <button
              className={classes.buttonCancel}
              onClick={() => setOpen(false)}
            >
              {"Cancel"}
            </button>
            <button
              className={classes.button}
              type="button"
              onClick={(e) => {
                e.persist();
                logoutClick(e);
              }}
            >
              {"Logout"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            width: "100%",
            padding: "40px",
          },
        }}
        fullScreen={fullScreen}
        maxWidth={"md"}
        open={referopen}
        onClose={handleReferClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <div
            className={classes.modaltitle}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              {"Refer Your Friend"}
            </span>
            <span
              style={{
                float: "right",
                color: "rgba(0, 0, 0, 0.8)",
                width: "10%",
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                textAlign: "right",
              }}
            >
              <span
                style={{
                  width: "25px",
                  color: "#ffffff",
                  borderRadius: "50%",
                  fontSize: "14px",
                  height: "25px",
                  background: "#BDBDBD",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={handleReferClose}
                />
              </span>
            </span>
          </div>
        </DialogTitle>
        {/* <div style={{ border: '14px solid #EAF6E1' }} /> */}
        <DialogContent dividers>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={10} md={10}>
              <ReferFriends {...props} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            width: "100%",
            // padding: '40px'
          },
        }}
        fullScreen={fullScreen}
        maxWidth={"md"}
        open={helpopen}
        onClose={handleHelpClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <div
            className={classes.modaltitle}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span
              style={{
                width: "90%",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {"NearShopz Support"}
            </span>
            <span
              style={{
                float: "right",
                color: "rgba(0, 0, 0, 0.8)",
                width: "10%",
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                textAlign: "right",
              }}
            >
              <span
                style={{
                  width: "25px",
                  color: "#ffffff",
                  borderRadius: "50%",
                  fontSize: "14px",
                  height: "25px",
                  background: "#BDBDBD",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={handleHelpClose}
                />
              </span>
            </span>
          </div>
        </DialogTitle>
        {/* <div style={{ border: '14px solid #EAF6E1' }} /> */}
        <DialogContent dividers>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={10} md={10}>
              <Help {...props} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            width: "100%",
          },
        }}
        fullScreen={fullScreen}
        maxWidth={"md"}
        open={accountopen}
        onClose={handleAccountClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <div
            className={classes.modaltitle}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span
              style={{
                width: "90%",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {"My Account"}
            </span>
            <span
              style={{
                float: "right",
                color: "rgba(0, 0, 0, 0.8)",
                width: "10%",
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                textAlign: "right",
              }}
            >
              <span
                style={{
                  width: "25px",
                  color: "#ffffff",
                  borderRadius: "50%",
                  fontSize: "14px",
                  height: "25px",
                  background: "#BDBDBD",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={handleAccountClose}
                />
              </span>
            </span>
          </div>
        </DialogTitle>
        {/* <div style={{ border: '14px solid #EAF6E1' }} /> */}

        <DialogContent dividers style={{ paddingBottom: "35px" }}>
          <form>
            <MyAccount {...props} />
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            width: "100%",
          },
        }}
        fullScreen={fullScreen}
        maxWidth={"md"}
        open={deleteopen}
        onClose={handleDeleteClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <div
            className={classes.modaltitle}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              {"Delete Account"}
            </span>
            <span
              style={{
                float: "right",
                color: "rgba(0, 0, 0, 0.8)",
                width: "10%",
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                textAlign: "right",
              }}
            >
              <span
                style={{
                  width: "25px",
                  color: "#ffffff",
                  borderRadius: "50%",
                  fontSize: "14px",
                  height: "25px",
                  background: "#BDBDBD",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={handleDeleteClose}
                />
              </span>
            </span>
          </div>
        </DialogTitle>
        {/* <div style={{ border: '14px solid #EAF6E1' }} /> */}
        <DialogContent dividers>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} md={12}>
              <Formik
                initialValues={{
                  reason: "",
                }}
                validationSchema={Schema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }) => {
                  return (
                    <form onSubmit={(values) => handleSubmit(values)}>
                      Please note that account deletion is a permanent action
                      and once your account is deleted it will no longer be
                      available to you and cannot be restored. If you decide
                      later that you want to start ordering from us again or if
                      you would like to use products and services that require
                      an account, you will need to create a new account.
                      <br />
                      <div
                        style={{ paddingTop: "15px", paddingBottom: "15px" }}
                      >
                        <b>
                          Please enter the main reason for deleting your
                          NearShopz account
                        </b>
                      </div>
                      <TextField
                        id="oldpasswordtextfield"
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        // InputProps={inputProps}
                        // InputLabelProps={inputLabelProps}
                        fullWidth
                        variant="outlined"
                        type={"text"}
                        name="reason"
                        // label="Reason"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.reason}
                        InputProps={{
                          style: {
                            width: "100%",
                            borderRadius: "12px",
                            fontSize: "16px",
                            height: "56px",
                          },
                          autoComplete: "off",
                        }}
                        error={touched.reason && errors.reason}
                        helperText={touched.reason && errors.reason}
                      />
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          paddingTop: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap:"10px"
                        }}
                      >
                        {/* <span style={{ paddingRight: "10px", width: "25%" }}> */}
                          <button
                            style={{
                              height: "48px",
                              width: "50%",
                              backgroundColor: "white",
                              color: "#85BE49",
                              fontSize: "16px",
                              fontWeight: "600px",
                              border: "1px solid #85BE49",
                              borderRadius: "12px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteClose()}
                          >
                            Cancel
                          </button>
                        {/* </span> */}
                        <button
                          style={{
                            height: "48px",
                            width: "50%",
                            backgroundColor: "#85BE49",
                            color: "white",
                            fontSize: "16px",
                            fontWeight: 600,
                            border: "none",
                            borderRadius: "12px",
                            cursor: "pointer",
                          }}
                          type="submit"
                        >
                          Delete
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AccountPopOver;
