import React from 'react';
import './style.css';

const Category1Carousel = (props) => {

    const [open, setOpen] = React.useState(false);
    const [categoryopen, setCategoryOpen] = React.useState(false);
    const [category1open, setCategory1Open] = React.useState(false);
    const setCategory1Id = (id) => {
        localStorage.setItem("category1id", id);
        props.props.getCategory1Products({
            storeid: localStorage.getItem('storeid'),
            categoryid1: localStorage.getItem('category1id'),
            userid: localStorage.getItem('userid')
        });
        props.navigate(`/stores/categories/category1products`);
    };
    React.useEffect(() => {
        if (!props.props.isDepartments) {
            if (props.params.storeid)
                props.props.getDepartments(props.params.storeid);
            else
                props.props.getDepartments(localStorage.getItem('storeid'));
        }
    },
        // eslint-disable-next-line
        [])
    return (
        <>
            <div className='category-list-container'>
                <div className='horizontal-scroll-list'>
                    <div className='category-container-div'>
                        {props.isCategories1 && props.isCategories1 === true &&
                            props.Categories1 && props.Categories1 !== null && props.Categories1.length > 0 &&
                            props.Categories1.map(({ name, id, childCategoryPresent, category1Products }) =>
                                category1Products.items.length > 0 &&
                                    childCategoryPresent === false ? (
                                    <div className='category-padding'>
                                        <div className='category-list-div' onClick={() => {
                                            setCategory1Id(id);
                                        }}>
                                            <div className='category-name-div'>
                                                {name}
                                            </div>
                                        </div>
                                    </div>
                                ) : null)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Category1Carousel