import * as React from 'react';
import { Grid } from "@mui/material";
import AlertBar from '../AlertBar';
import PublicIcon from '@mui/icons-material/Public';
import FileCopyIcon from '@mui/icons-material/FileCopy';

class WebLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: 'https://www.nearshopz.com/',
            copySuccess: false
        }
    }
    copyCodeToClipboard = () => {
        const el = this.input
        el.select()
        document.execCommand("copy")
        this.setState({ copySuccess: true })
        setTimeout(() => {
            this.setState({ copySuccess: false })
        }, (3000))
    }
    render() {
        return (
            <>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <div style={{
                            color: "#061303",
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: '22px',
                            paddingBottom: "7px",
                            paddingTop: "40px",
                        }}>
                            {'Share our website link'}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div
                            style={{
                                width: '100%',
                                height: '45px',
                                backgroundColor: 'rgba(105, 168, 92, 0.1)',
                                borderRadius: '13px',
                                border: '1px solid rgba(0,0,0,0.1)',
                                boxShadow: '0px 8px 44px rgba(0, 0, 0, 0.04)',
                                color: 'rgba(105, 168, 92, 0.5)',
                                fontSize: '14px',
                                paddingLeft: '5px',
                                // paddingTop: '5px',
                                // paddingBottom: '5px',
                                paddingRight: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <div
                                style={{
                                    backgroundColor: 'rgba(105, 168, 92, 0.2)',
                                    borderRadius: '9px',
                                    width: '40px',
                                    height: '35px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}>
                                <PublicIcon />
                            </div>
                            <input
                                label="invite link"
                                style={{
                                    background: 'transparent',
                                    display: 'flex',
                                    border: 'none',
                                    width: '100%',
                                    textAlign: 'left',
                                    fontFamily: 'Nunito',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: '1rem',
                                    lineHeight:'1.25rem',
                                    paddingLeft: '13px',
                                    color: 'rgba(105, 168, 92, 0.8)'
                                }}
                                ref={(input) => this.input = input}
                                type="text"
                                value={this.state.url}
                                readOnly
                            />
                            <span
                                onClick={() => this.copyCodeToClipboard()}
                                style={{
                                    float: 'right',
                                    textAlign: 'right',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'rgba(105, 168, 92, 0.8)',
                                    cursor: 'pointer'
                                }}>
                                <FileCopyIcon />
                                &nbsp;{'Copy'}
                            </span>
                        </div>
                    </Grid>
                    {/* <Grid item xs={1} md={0} sx={{display:{md:'none',xs:'flex'}}}/>
                    <Grid item xs={12} md={3} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                        <button
                        style={{background: `${buttongreen}`,
                        color: '#FFFFFF',
                        fontSize: '14px',
                        lineHeight: '19px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        borderRadius: '26.5px',
                        padding: '13px',
                        border: `1px ${buttongreen}`,
                        width: '80%',
                        cursor: 'pointer',}}
                            onClick={() => this.copyCodeToClipboard()}>
                            Copy URL
                        </button>
                    </Grid> */}
                    {/* <Grid item xs={1} md={0}/> */}
                </Grid>
                <Grid>
                    {
                        this.state.copySuccess ?
                            <AlertBar type={'success'} message={"Copied to Clipboard"} props={this.props} {...this.props} /> : null
                    }
                </Grid>
            </>
        );
    }
}

export default WebLink