import React from "react";
import Businesstypes from "../../Components/Businesstypes";
import StoreCard from "../../Components/StoreCard";
import MobStoreCard from "../../Components/StoreCard/MobStoreCard";
import Grid from "@mui/material/Grid";
import nolocation from "../../Assets/Images/nolocation.webp";
import Location from "../../Components/Location";
import { useMediaQuery } from "react-responsive";
import "./index.css";
import CenteredCircularProgress from "./CenteredCircularProgress";
import { useTheme } from "@mui/material";

const StoreListing = (props) => {
  React.useEffect(
    () => {
      props.getStores(
        localStorage.getItem("lattitude"),
        localStorage.getItem("longitude")
      );
    },
    // eslint-disable-next-line
    [localStorage.getItem("lattitude"), localStorage.getItem("longitude")]
  );

  const isSmallScreen = useMediaQuery({ query: "(max-width: 700px)" });
  const ismobileScreen = useMediaQuery({ query: "(min-width: 320px)" });
  React.useEffect(() => {
    document.title = "Explore and Order Products from Shops Nearby | NearShopz";
    document.getElementsByTagName("META")[2].content =
      "Explore products from shops nearby. Order now or at your convenience. Make payments securely. Download the NearShopz mobile App!";
  });
  // const theme = useTheme(); 
  // const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return isSmallScreen ? (
    <>
      {!localStorage.getItem("location") || localStorage.getItem("location") === null ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "15px",
            paddingBottom: "35px",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "25px",
            color: "#000000",
            height: `calc(100vh - 145px)`, // Make sure this is the full height
            justifyContent: "center", // Center vertically
            alignItems: "center", // Center horizontally
            textAlign: "center",
            background: "#FFFFFF",
            borderRadius: "13px",
          }}
        >
          <img
            src={nolocation}
            alt="No Stores"
            className="no-stores-div-image"
            style={{ width: "200px", height: "200px", marginBottom: ismobileScreen ? "0px": "15px"}} // Add margin for spacing
          />
          <div className="noMessage">{"Uh-oh !!!"}</div>
          <div className="noMessages">{"Please select a location to view stores"}</div>
          <div className="location-button-div">
            <Location componentkey="store" {...props} />
          </div>
        </div>
      ) : props.isStoresSuccess && props.Stores && props.Stores.length === 0 ? (
        <div className="root1">
          <div className="no-stores-div">
            <img
              src={nolocation}
              alt="No Stores"
              className="no-stores-div-image"
              style={{ width: "200px", height: "200px", marginBottom: ismobileScreen ? "0px": "15px" }} // Add margin for spacing
            />
            <div className="noMessage">{"Sorry! Currently, we do not have stores onboarded in your area."}</div>
            <div className="noMessages">{"Please select another location to view stores"}</div>
            <div className="location-button-div">
              <Location componentkey="store" {...props} />
            </div>
          </div>
        </div>
      )  : (
        <div style={{ paddingTop: ismobileScreen ? "30px": "15px" }}>
          <Businesstypes {...props} />
          <div className="root" style={{ marginTop: "0" }}>
            <p>{"Your NearShopz"}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 15,
              paddingRight: 15,
              paddingBottom: "30px",
            }}
          >
            {props.isStoresSuccess &&
            props.Stores &&
            props.Stores !== null &&
            props.Stores !== "Unauthenticated!" &&
            props.Stores !== [] &&
            props.Stores.length > 0
              ? props.Stores.map((store) => (
                  <div style={{ paddingBottom: "10px" }}>
                    <MobStoreCard
                      navigate={props.navigate}
                      Storename={store.storeName}
                      StoreLogo={store.storeLogoUrl}
                      storeid={store.id}
                      subtitle={store.businessType}
                      storelocation={store.storeLocation}
                      storeDescription={store.storeDescription}
                      props={props}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      )}
    </>
  ) : (
    <>
      {!localStorage.getItem("location") ||
      localStorage.getItem("location") === null ? 
      (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "15px",
            paddingBottom: "35px",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "25px",
            color: "#000000",
          }}
        >
          <div
            style={{
              width: "100%",
              height: `calc(100vh - 145px)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              background: "#FFFFFF",
              borderRadius: "13px",
              flexDirection: "column",
            }}
          >
            <img
              src={nolocation}
              alt="No-Stores"
              className="no-stores-div-image"
              style={{ width: "250px", height: "250px" }}
            />
            <div className="noMessage">{"Uh-oh !!!"}</div>
            <div className="noMessages">
              {"Please select a location to view stores"}
            </div>
            <div className="location-button-div">
              <Location componentkey="store" {...props} />
            </div>
          </div>
        </div>
      ) : props.isStoresSuccess &&
        props.Stores &&
        props.Stores !== null &&
        props.Stores !== "Unauthenticated!" &&
        props.Stores.length === 0 ? (
        <div className="root">
          <div className="no-stores-div">
            <img
              src={nolocation}
              alt="No-Stores"
              className="no-stores-div-image"
              style={{ width: "250px", height: "250px" }}
            />
            <div className="noMessage">
              {
                "Sorry ! Currently we do not have stores onboarded in your area. We shall notify you as soon as we have stores ready."
              }
            </div>
            <div className="noMessages">
              {"Please select another location to view stores"}
            </div>
            <div className="location-button-div">
              <Location componentkey="store" {...props} />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ paddingTop: ismobileScreen ? "30px": "15px" }}>
              {
              props.isStoresSuccess &&
              props.Stores &&
              props.Stores !== null &&
              props.Stores !== "Unauthenticated!" &&
              props.Stores !== [] &&
              props.Stores.length > 0
                ? 
                <>
          <Businesstypes {...props} />
          <div className="root" style={{ marginTop: 0 }}>
            <p className="gridDiv-heading">{"Your NearShopz"}</p>
          </div>
          <Grid item xs={12} >
            <Grid container spacing={2} sx={{marginLeft:"0px"}}>
                {props?.Stores?.map((store) => (
                    <Grid key={store.id} item>
                      <StoreCard
                        navigate={props.navigate}
                        Storename={store.storeName}
                        StoreLogo={store.storeLogoUrl}
                        storeid={store.id}
                        subtitle={store.businessType}
                        storelocation={store.storeLocation}
                        props={props}
                      />
                    </Grid>
                  ))}
               
            </Grid>
          </Grid>
          </>
           : 
           
         <CenteredCircularProgress/>
           }
        </div>
      )}
    </>
  );
};

export default StoreListing;
