// MatchingResults.js
import React from "react";
import { ReactComponent as RightArrow } from "../../Assets/Icons/rightarrow.svg";
import { useMediaQuery } from "react-responsive";

const MatchingResults = ({ matchingProductList,setSeletcedProductname,props }) => {
  
  const routeToStore = (e, id,storeLogo,Storename,storeStatus,selectedProductId,productname,image,strikeOffPrice,markedupProductPrice,promoprice,quantity,uom,bxgy) => {
    // e,id,logo,name,status,id,name,img,price,promoprice,qnty,uom,bxgy
    e.persist();
    props.getDepartments(id);
    localStorage.setItem("storeLogo",storeLogo);
    localStorage.setItem('storeid', id);
    localStorage.setItem("storeName", Storename);
    localStorage.setItem("businessType", props.businessType);
    localStorage.setItem("storelocation", props.storelocation);
    props.navigate(`/stores/categories`, { 
      state: { 
          productId: selectedProductId,
          productname:productname,
          image:image,
          strikeOffPrice:strikeOffPrice,
          markedupProductPrice:markedupProductPrice,
          promoprice:promoprice,
          storeId: id,
          storename:localStorage.getItem("storeName"),
          uom:uom,
          quantity:quantity,
          
            bxgy:bxgy
              ? {
                  active: bxgy.active || null,
                  xvalue: bxgy.xvalue || null,
                  yvalue: bxgy.yvalue || null
                }
              : null
          
      } 
  });
    
  }
  
  const isMobileScreen = useMediaQuery({ query: "(max-width: 430px)" });
  return (
    
    <div className="matching_result_conatiner" id="matchingResults">
      <p className="matching-reslt-head">{`Matching Results For  "${setSeletcedProductname}"`} </p>
      <div className= {isMobileScreen ?  "horizontal-scroll1" : "matching_result_wrapper" }>
          {matchingProductList?.items.map((item) => {
            
        return item?.products.map((product) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", flexWrap: "wrap" }}>   
            <div key={product.id} className="store_conatiner">
              <div >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap:"20px"
                  }}
                >
                  <p className="store_name">{item?._id?.storeName}</p>
                  <div>
                    <RightArrow
                      className="wid-100"
                      onClick={(e) =>
                        routeToStore(
                          e,
                          item?._id?.id,
                          item?._id?.storeLogoUrl,
                          item?._id?.storeName,
                          item?._id?.storeStatus,
                          product.id,
                          product.productname,
                          product?.image?.primary,
                          product?.strikeOffPrice,
                          product?.markedupProductPrice,   
                          product.promoprice,
                          product.quantity,
                          product.uom,
                          product?.bxgy || {}
                        )
                      }
                    />
                  </div>
                </div>
                <div className="product_details_div">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        height: "100%",
                      }}
                    >
                      {" "}
                      <img
                        src={product?.image?.primary}
                        alt="product-img"
                        style={{
                          height: "60px",
                          objectFit: "contain",
                        }}
                      ></img>
                    </div>
                    <div>
                      <p className="product_name_prop">
                        {product?.productname}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div>
                   
                      <p className="product_price">{`₹ ${ parseFloat(product?.markedupProductPrice)?.toFixed(2)}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          );
        });
      })}
      </div>
    </div>
   
  );
};

export default MatchingResults;
