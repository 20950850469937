import React from 'react';
import { useStyles } from "./style.js";
import OrderCard from '../OrderCard';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import emptycart from '../../Assets/Images/emptycart.svg';
// import OrdersLoader from '../../Loaders/OrdersLoader.js';
import OrderDetails from '../OrderCard/OrderDetails.js';
import Noorders from './Noorders.js';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ReportIssue from '../OrderCard/ReportIssue.js';
import ReactStars from "react-rating-stars-component";
import { DialogContentText } from '@material-ui/core';
import { withStyles } from "@mui/styles";
import Loader from '../LoaderGif.js'

const GreenCheckbox = withStyles({
  root: {
    color: '#66BB6A',
    "&$checked": {
      color: '#43A047',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const MyOrders = (props) => {
  const classes = useStyles();
  const [active, setActive] = React.useState(true);
  const [completed, setCompleted] = React.useState(false);
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const desktop = useMediaQuery(useTheme().breakpoints.up('md'));
  const Title = ({ children }) => (
    <div className={classes.reviewheading}>{children}</div>
  );
  const [state, setState] = React.useState({
    right: false,
    orderid: '',
    storelogo: '',
    Status: '',
    text: '',
    storename: '',
    ordernumber: '',
    product: '',
    total: '',
    totalprice: '',
    delivery: '',
    markedupProductPrice:""
  });
  const selectActive = () => {
    setActive(true);
    setCompleted(false);
    props.getActiveOrders(localStorage.getItem('userid'));
  };
  const selectComplete = () => {
    setActive(false);
    setCompleted(true);
    props.getCompletedOrders({ userid: localStorage.getItem('userid'), first: 10, offset: 0 })
  };
  React.useEffect(() => {
    props.getActiveOrders(localStorage.getItem('userid'));
    setState({
      ...state, right: false,
      orderid: '',
      storelogo: '',
      Status: '',
      text: '',
      storename: '',
      ordernumber: '',
      product: '',
      total: '',
      totalprice: '',
      delivery: '',
      markedupProductPrice:''
    })
  },
    //eslint-disable-next-line
    []);
  const [feedbackopen, setFeedbackopen] = React.useState(false);
  const [reviewopen, setReviewopen] = React.useState(false);
  const [starrating, setRating] = React.useState('');
  const [areastoimprove, setAreastoimprove] = React.useState([]);

  const handlecommentChange = (e) => {
    setState({ ...state, comments: e.target.value });
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setAreastoimprove([...areastoimprove, event.target.value]);
  };

  const handleFeedbackClose = () => {
    setFeedbackopen(false);
  };
  const handleReviewClose = () => {
    setReviewopen(false);
  };
  const ratingChanged = (id, newRating) => {

    if (newRating === 5) {
      props.addrating(id, newRating, "", "");
      setFeedbackopen(true);
    } else if (newRating < 5) {
      setState({ ...state, rating: newRating, orderid: id });
      setRating(newRating);
      setReviewopen(true);
    }
  };

  const rating = (state) => {
    props.addrating(
      state.orderid,
      starrating,
      areastoimprove,
      state.comments
    );
    setState({
      delivery: false,
      packaging: false,
      quality: false,
      quantity: false,
      behaviour: false,
      rating: "",
      orderid: "",
      comments: "",
    });
    setAreastoimprove([]);
    handleReviewClose();
  };
  const [messages, setMessages] = React.useState(null);
  const [liveorderdata, setLiveorderdata] = React.useState(0);
  const [status, setStatus] = React.useState(0);

  React.useEffect(() => {
    const messageListener = (message) => {
      if (message !== null) {
        setMessages((prevMessages) => {
          const newMessages = { ...prevMessages };
          newMessages[message.id] = message;
          setStatus(message);
          setLiveorderdata({
            orderNumber: message.orderNumber,
            lastStatus: message.lastStatus,
            notifications: message.notifications,
            created: message.createdAt
          })
          return newMessages;
        });
      }
      else return null;

    };

    const deleteMessageListener = (messageID) => {
      setMessages((prevMessages) => {
        const newMessages = { ...prevMessages };
        delete newMessages[messageID];
        return newMessages;
      });
    };

    if (props.socket !== null) {
      props.socket.on(`${localStorage.getItem('userid')}_order`, messageListener);
      props.socket.on('deleteMessage', deleteMessageListener);
      props.socket.emit('getMessages');
    }

    return () => {
      if (props.socket !== null) {
        props.socket.off(`${localStorage.getItem('userid')}_order`, messageListener);
        props.socket.off('deleteMessage', deleteMessageListener);
      }
    };
  }, [props.socket]);
  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ paddingTop: '50px' }}>
        <Grid item xs={12} md={12}>
          <div style={{ paddingBottom: '15px', display: 'flex', alignItems: 'center' }}>
            <span className={classes.leftarrow} onClick={() => props.navigate(-1)}>
              <KeyboardBackspaceIcon />
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={active ? classes.active : classes.ordertab} onClick={() => selectActive()}>
              <span>
                {'Active'}&nbsp;
              </span>
              <span className={active ? classes.numberactive : classes.numbercomplete}>
                {`${props.ActiveOrdersLength && props.ActiveOrdersLength}`}
              </span>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;
            <span className={completed ? classes.active : classes.ordertab} onClick={() => selectComplete()}>
              {'Completed'}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ paddingBottom: '70px', width: '100%', paddingTop: '30px' }} >
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {props.isordersLoading && props.isordersLoading === true ?
              <Loader/> :
              props.isorders && props.isorders === true ? (
                (props.Orders && props.Orders !== null &&
                  props.Orders.length > 0) ?
                  props.Orders.map((s) =>
                    <>
                      <Grid item xs={12} md={5}>
                        <div
                          style={{ cursor: 'pointer' }} onClick={() => {
                            props.getOrderdetails(s.id);
                            setState({
                              ...state,
                              right: true,
                              orderid: s.id,
                              storelogo: s.storeid.storeLogoUrl,
                              Status: s.lastStatus,
                              text: s.lastStatus,
                              storename: s.storeid.storeName,
                              ordernumber: s.orderNumber,
                              product: s.products.length,
                              total: s.totalPayable,
                              totalprice: s.totalPayable,
                              delivery: s.deliveryCharge,
                              markedupProductPrice:s.markedupProductPrice
                            })
                            localStorage.setItem("selectedordernumber", s.orderNumber)
                          }}>
                          {(
                            (props.socket &&
                              messages !== null &&
                              liveorderdata !== 0 &&
                              liveorderdata.orderNumber &&
                              liveorderdata.orderNumber === s.orderNumber &&
                              liveorderdata.lastStatus === 'Completed')
                          )
                            ?
                            null :
                            <OrderCard
                              id={s.id}
                              type={'activeorder'}
                              ordernumber={s.orderNumber}
                              storename={s.storeid.storeName}
                              storelogo={s.storeid.storeLogoUrl}
                              product={s.products.length}
                              total={s.totalPrice}
                              delivery={s.deliveryCharge}
                              totalprice={s.totalPriceDelivery}
                              finalBillAmount={s.finalBillAmount}
                              Status={s.lastStatus}
                              text={s.lastStatus}
                              discountPrice={s.discountPrice}
                              props={props}
                              version={s.version}
                              totalPayable={s.totalPayable}
                              total2={s.total}
                              handlingCharge={s.handlingCharge}
                              packingCharge={s.packingCharge}
                              GST={s.GST}
                              deliveryTip={s.deliveryTip}
                              {...props} />
                          }
                        </div>
                      </Grid>
                      <Grid item xs={12} md={1} />
                    </>
                  ) :
                  (
                    props.Orders.items && props.Orders.items.length > 0 ?
                      props.Orders.items.map((s) =>
                        <>
                          <Grid item xs={12} md={5}>
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                props.getOrderdetails(s.id);
                                setState({
                                  ...state,
                                  right: true,
                                  orderid: s.id,
                                  storelogo: s.storeid.storeLogoUrl,
                                  Status: s.lastStatus,
                                  text: s.lastStatus,
                                  storename: s.storeid.storeName,
                                  ordernumber: s.orderNumber,
                                  product: s.products.length,
                                  total: s.totalPrice,
                                  totalprice: s.totalPrice,
                                  delivery: s.deliveryCharge,
                                  markedupProductPrice:s.markedupProductPrice
                                })
                              }}>
                              <OrderCard
                                id={s.id}
                                orderid={s.id}
                                type={'completedorder'}
                                ordernumber={s.orderNumber}
                                storename={s.storeid.storeName}
                                storelogo={s.storeid.storeLogoUrl}
                                product={s.products.length}
                                total={s.totalPrice}
                                delivery={s.deliveryCharge}
                                totalprice={s.totalPriceDelivery}
                                Status={s.lastStatus}
                                text={s.lastStatus}
                                finalBillAmount={s.finalBillAmount}
                                orderrating={s.ratings && s.ratings.rating}
                                discountPrice={s.discountPrice}
                                version={s.version}
                                totalPayable={s.totalPayable}
                                total2={s.total}
                                handlingCharge={s.handlingCharge}
                                packingCharge={s.packingCharge}
                                GST={s.GST}
                                deliveryTip={s.deliveryTip}
                                props={props}
                                {...props} />
                            </div>
                            <div style={{ width: '100%', justifyContent: 'center', backgroundColor: '#fff', display: 'flex', textAlign: 'center', alignItems: 'center' }}>
                              <ReactStars
                                value={s.ratings && s.ratings.rating}
                                count={5}
                                size={35}
                                onChange={(e) =>
                                  ratingChanged(s.id, e)
                                }
                                activeColor="#ffd700"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={1} />
                        </>
                      )
                      :
                      <div className={classes.noordersDiv}>
                        <img src={emptycart} alt="noorders" className={classes.noordersimage} />
                        <p className={classes.noordersheading}>
                          {`You don't have any 
                                        ${props.isCompletedeorders && props.isCompletedeorders === true ? 'completed' : ''}
                                         order`}
                        </p>
                        {props.isActiveorders && props.isActiveorders === true &&
                          <>
                            <p className={classes.noorderstext}>
                              {'Browse items and make your purchase to order'}
                            </p>
                          </>
                        }
                      </div>
                  )) : props.isordersFailed && props.isordersFailed === true &&
              'OOPS'}
          </Grid>
        </Grid>
        <Divider flexItem orientation='vertical' />
        <Grid item xs={0} md={0.25} />
        <Grid item xs={0} md={3}>
          {(state.right === true && desktop === true) ?
            <div>
              <div className={classes.orderDetailsHeading} style={{ paddingLeft: '35px', paddingRight: '35px' }}>
                <div
                  className={classes.ordermodalHeading}>
                  <span className={classes.ordernumberfirst}>
                    <b>{`ORDER#`}</b>
                  </span>
                  <span className={classes.ordernumbersecondary}>&nbsp;{state.ordernumber}</span>
                </div>
                <div className={classes.reportissueDiv}>
                  <ReportIssue
                    orderID={state.orderid}
                    ordertype={props.isorders && props.isorders === true &&
                      (props.Orders && props.Orders !== null &&
                        props.Orders.length > 0) ? 0 : 1}
                    {...props} />
                </div>
              </div>
              <OrderDetails
                orderid={state.orderid}
                storelogo={state.storelogo}
                Status={state.Status}
                text={state.text}
                storename={state.storename}
                ordernumber={state.ordernumber}
                product={state.product}
                total={state.total}
                totalprice={state.totalprice}
                delivery={state.delivery}
                socket={props.socket}
                props={props}
                {...props} />
            </div>
            :
            (state.right === true && desktop === false) ?
              <Dialog
                open={state.right}
                maxWidth={'sm'}
                // fullScreen={true}
                PaperProps={{ style: { position: 'relative', zIndex: 1000 } }}
                onClose={() => setState({ ...state, right: false })}
              >
                <DialogTitle className={classes.modalHeading}>
                  <span style={{ float: 'right' }}
                    onClick={() => setState({ ...state, right: false })}>
                    <CloseIcon style={{ cursor: 'pointer' }}
                      onClick={() => setState({ ...state, right: false })} />
                  </span>
                </DialogTitle>
                <DialogContent>
                  <div
                    className={classes.ordermodalHeading}>
                    <span className={classes.ordernumberfirst}>
                      <b>{`ORDER#`}</b>
                    </span>
                    <span className={classes.ordernumbersecondary}>&nbsp;{state.ordernumber}</span>
                    <span
                      style={{ float: 'right', width: '100%', justifyContent: 'right', textAlign: 'right' }}>
                      <ReportIssue
                        orderID={state.orderid}
                        ordertype={props.isorders && props.isorders === true &&
                          (props.Orders && props.Orders !== null &&
                            props.Orders.length > 0) ? 0 : 1}
                        {...props} />
                    </span>
                  </div>
                  <OrderDetails
                    orderid={state.orderid}
                    storelogo={state.storelogo}
                    Status={state.Status}
                    text={state.text}
                    storename={state.storename}
                    ordernumber={state.ordernumber}
                    product={state.product}
                    total={state.total}
                    markedupPrice={state.markedupProductPrice}
                    totalprice={state.totalprice}
                    delivery={state.delivery}
                    socket={props.socket}
                    props={props}
                    {...props} />
                </DialogContent>
              </Dialog>
              :
              desktop &&
              <Noorders />
          }
        </Grid>
      </Grid>

      <Dialog
        open={reviewopen}
        onClose={handleReviewClose}
        transitionDuration={1000}
        disableBackdropClick={true}
        fullScreen={fullScreen}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Title>{"Areas to improve"}</Title>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            <div className={classes.form}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.delivery}
                      onChange={handleChange}
                      name="delivery"
                      value="On time delivery"
                    />
                  }
                  label="On time delivery"
                />
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.packaging}
                      onChange={handleChange}
                      name="packaging"
                      value="Proper packaging"
                    />
                  }
                  label="Proper packaging"
                />
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.quality}
                      onChange={handleChange}
                      name="quality"
                      value="Quality of the items"
                    />
                  }
                  label="Quality of the items"
                />
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.quantity}
                      onChange={handleChange}
                      name="quantity"
                      value="Quantity of the items"
                    />
                  }
                  label="Quantity of the items"
                />
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.behaviour}
                      onChange={handleChange}
                      name="behaviour"
                      value="Behavior of the delivery person"
                    />
                  }
                  label="Behavior of the delivery person"
                />
              </FormGroup>
            </div>
            <div className={classes.form}>
              <TextField
                fullWidth
                label="Comments, if any"
                multiline
                rows={4}
                variant="outlined"
                onChange={(e) => handlecommentChange(e)}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ textAlign: "center", alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%' }}>
            <button
              onClick={() => rating(state)}
              style={{
                backgroundColor: 'rgb(137, 199, 74, 1)',
                border: "none",
                color: "#fff",
                fontWeight: 600,
                borderRadius: '12px',
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '20px',
                paddingRight: '20px',
                width: '80%',
              }}
            >
              Submit
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={feedbackopen}
        onClose={handleFeedbackClose}
        PaperProps={{
          style: {
            borderRadius: '10px',
            backgroundColor: '#ffffff',
            width: '100%',
          }
        }}
        maxWidth={'xs'}
        transitionDuration={1000}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            <div style={{ textAlign: "right" }}>
              <button
                onClick={handleFeedbackClose}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "rgb(137, 199, 74, 1)",
                  fontWeight: 600,
                }}
              >
                X
              </button>
            </div>
            <div className='ModalContent'>
              Thank you for your feedback !!!
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default MyOrders