import React from 'react';
import { AppBar, Box, Dialog, DialogContent, DialogTitle, Drawer, Grid, useMediaQuery, useTheme } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CloseIcon from '@mui/icons-material/Close';
import SelectItem from './SelectItem';
import UploadImage from './UploadImage';
import SelectOption from './SelectOption';
import { useStyles } from './style';

const ComplaintsList = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedcomplaints, setSelectedComplaints] = React.useState([]);
    const [registercomplaintopen, setRegisterComplaintOpen] = React.useState(false);
    const [selectoptionopen, setSelectOptionComplaintOpen] = React.useState(false);
    const [selectedComplaintIndex, setselectedComplaintIndex] = React.useState(0);
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('md'));
    const desktop = useMediaQuery(theme.breakpoints.up('md'));

    const openDrawer = () => {
        setOpen(true);
        props.getOrderItemslist(props.orderID)
    };
    const drawerClose = () => {
        setOpen(false);
    };
    const getsubComplaintsList = (id, index, message) => {
        openDrawer();
        setSelectedComplaints([message]);
        props.getSubcomplaintslist(id);
    }
    return (
        <>
            {props.iscomplaintslist &&
                props.iscomplaintslist === true &&
                props.Complaints && props.Complaints !== null &&
                props.Complaints.length > 0 &&
                props.Complaints.map((complaint, index) =>
                    <div
                        onClick={() => getsubComplaintsList(complaint.id, index, complaint.message)}
                        key={index}
                        className={classes.complaintsList}>
                        {complaint.message}
                    </div>
                )}
            {
                desktop ?
                    <Drawer
                        open={open}
                        onClose={drawerClose}
                        anchor='right'
                        sx={{
                            '& .MuiDrawer-paper':
                            {
                                boxSizing: 'border-box',
                                width: { xs: '100%', sm: '100%', md: 500 },
                                backgroundColor: '#EAEDEF'
                            },
                        }}>
                        <AppBar
                            position={'fixed'}
                            sx={{
                                top: 0,
                                bottom: "auto",
                                width: { xs: '100%', sm: '100%', md: 500 },
                                backgroundColor: "#fff",
                                border: 'none',
                                boxShadow: 'none',
                                height: "100px",
                                paddingTop: "10px",
                                color: '#000',
                                justifyContent: 'center'
                            }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#000000'
                                }}>
                                <div
                                    style={{
                                        paddingBottom: '10px',
                                        width: '100%'
                                    }}>
                                    <Grid container style={{ width: '100%' }}>
                                        <Grid item xs={0.5} />
                                        <Grid item xs={2}
                                            justifyContent={'center'}
                                            textAlign={'center'}
                                            alignItems={'center'}>
                                            <span className={classes.leftarrow} onClick={() => drawerClose()}>
                                                <KeyboardBackspaceIcon />
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} justifyContent={'left'} textAlign={'left'} alignItems={'center'}
                                            style={{ width: '100%' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'left',
                                                    alignItems: 'center',
                                                    textAlign: 'left'
                                                }}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span
                                                        style={{
                                                            color: '#000000',
                                                            fontStyle: 'normal',
                                                            fontWeight: 'bold',
                                                            fontSize: '24px',
                                                            lineHeight: '33px',
                                                        }}>
                                                        {selectedcomplaints[0] && selectedcomplaints[0]}
                                                    </span>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </AppBar>
                        <Box
                            style=
                            {{
                                paddingTop: '120px',
                                paddingBottom: '20px',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'center',
                                width: '100%',
                                paddingLeft: '20px',
                                paddingRight: '20px'
                            }}>
                            {props.issubcomplaintslist &&
                                props.issubcomplaintslist === true &&
                                props.SubComplaints && props.SubComplaints !== null &&
                                props.SubComplaints.length > 0 &&
                                props.SubComplaints.map((complaint, index) =>
                                    <div
                                        onClick={() => {
                                            setRegisterComplaintOpen(true);
                                            props.resetComplaintProps();
                                            setselectedComplaintIndex(index);
                                            setSelectedComplaints([...selectedcomplaints, complaint.message]);
                                        }}
                                        key={index}
                                        className={classes.complaintsList}>
                                        {complaint.message}
                                    </div>)}
                        </Box>
                    </Drawer>
                    :
                    <Dialog
                        open={open}
                        maxWidth={'sm'}
                        fullScreen={true}
                        PaperProps={{ style: { position: 'relative', zIndex: 1000, backgroundColor: '#EAEDEF' } }}
                        onClose={drawerClose}>
                        <AppBar
                            position={'fixed'}
                            sx={{
                                top: 0,
                                bottom: "auto",
                                width: { xs: '100%', sm: '100%', md: 500 },
                                backgroundColor: "#fff",
                                border: 'none',
                                boxShadow: 'none',
                                height: "100px",
                                paddingTop: "10px",
                                color: '#000',
                                justifyContent: 'center'
                            }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#000000'
                                }}>
                                <div
                                    style={{
                                        paddingBottom: '10px',
                                        width: '100%'
                                    }}>
                                    <Grid container style={{ width: '100%' }}>
                                        <Grid item xs={0.5} />
                                        <Grid item xs={2}
                                            justifyContent={'center'}
                                            textAlign={'center'}
                                            alignItems={'center'}>
                                            <span className={classes.leftarrow} onClick={() => drawerClose()}>
                                                <KeyboardBackspaceIcon />
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} justifyContent={'left'} textAlign={'left'} alignItems={'center'}
                                            style={{ width: '100%' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'left',
                                                    alignItems: 'center',
                                                    textAlign: 'left'
                                                }}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span
                                                        style={{
                                                            color: '#000000',
                                                            fontStyle: 'normal',
                                                            fontWeight: 'bold',
                                                            fontSize: '24px',
                                                            lineHeight: '33px',
                                                        }}>
                                                        {selectedcomplaints[0] && selectedcomplaints[0]}
                                                    </span>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </AppBar>
                        <Box
                            style=
                            {{
                                paddingTop: '120px',
                                paddingBottom: '20px',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'center',
                                width: '100%',
                                paddingLeft: '20px',
                                paddingRight: '20px'
                            }}>
                            {props.issubcomplaintslist &&
                                props.issubcomplaintslist === true &&
                                props.SubComplaints && props.SubComplaints !== null &&
                                props.SubComplaints.length > 0 &&
                                props.SubComplaints.map((complaint, index) =>
                                    <div
                                        onClick={() => {
                                            setRegisterComplaintOpen(true);
                                            props.resetComplaintProps();
                                            setselectedComplaintIndex(index);
                                            setSelectedComplaints([...selectedcomplaints, complaint.message]);
                                        }}
                                        key={index}
                                        className={classes.complaintsList}>
                                        {complaint.message}
                                    </div>)}
                        </Box>
                    </Dialog>
            }
            <Dialog
                open={registercomplaintopen}
                PaperProps={{
                    style: {
                        borderRadius: '10px',
                        backgroundColor: '#fff',
                        width: '100%',
                    }
                }}
                maxWidth={'sm'}
                onClose={() => setRegisterComplaintOpen(false)}>
                <DialogTitle
                    className={classes.modalHeading}>
                    <span
                        className={classes.modalHeading}>
                        {(props.issubcomplaintslist &&
                            props.issubcomplaintslist === true &&
                            props.SubComplaints && props.SubComplaints !== null &&
                            props.SubComplaints.length > 0 &&
                            props.SubComplaints[selectedComplaintIndex] &&
                            props.SubComplaints[selectedComplaintIndex].imageUpload === false &&
                            props.SubComplaints[selectedComplaintIndex].itemsList === false) ? "Register Complaint" : ""}
                    </span>
                    <span
                        style={{
                            width: '25px', float: 'right',
                            color: '#ffffff',
                            borderRadius: '50%',
                            fontSize: '14px',
                            height: '25px',
                            background: 'rgba(133, 190, 73, 1)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}>
                        <CloseIcon
                            style={{
                                cursor: 'pointer',
                                fontSize: '14px'
                            }}
                            onClick={() => setRegisterComplaintOpen(false)} />
                    </span>
                </DialogTitle>
                <DialogContent>
                    {props.issubcomplaintslist &&
                        props.issubcomplaintslist === true &&
                        props.SubComplaints && props.SubComplaints !== null &&
                        props.SubComplaints.length > 0 &&
                        props.SubComplaints[selectedComplaintIndex] &&
                        props.SubComplaints[selectedComplaintIndex].itemsList === true ?
                        props.orderdetailssuccess && props.orderdetailssuccess === true &&
                        <SelectItem
                            orderID={props.orderID}
                            {...props} />
                        :
                        null
                    }
                    {(props.issubcomplaintslist &&
                        props.issubcomplaintslist === true &&
                        props.SubComplaints && props.SubComplaints !== null &&
                        props.SubComplaints.length > 0 &&
                        props.SubComplaints[selectedComplaintIndex] &&
                        props.SubComplaints[selectedComplaintIndex].itemsList === true) ?
                        props.SelectedItemsList && props.SelectedItemsList !== null && props.SelectedItemsList.length > 0 &&
                        props.submititems === true &&
                        (props.SubComplaints[selectedComplaintIndex].imageUpload === true ?
                            <UploadImage
                                orderID={props.orderID}
                                {...props} />
                            : (
                                props.SubComplaints[selectedComplaintIndex].imageUpload === false) ?
                                <button
                                    className={classes.submitbutton}
                                    onClick={() => { setRegisterComplaintOpen(false); setSelectOptionComplaintOpen(true); setOpen(false) }}>
                                    {'Submit'}
                                </button>
                                : null)
                        :
                        (
                            props.issubcomplaintslist &&
                                props.issubcomplaintslist === true &&
                                props.SubComplaints && props.SubComplaints !== null &&
                                props.SubComplaints.length > 0 &&
                                props.SubComplaints[selectedComplaintIndex] &&
                                props.SubComplaints[selectedComplaintIndex].imageUpload === true ?
                                <UploadImage
                                    orderID={props.orderID}
                                    {...props} />
                                : null)
                    }
                    {(props.issubcomplaintslist &&
                        props.issubcomplaintslist === true &&
                        props.SubComplaints && props.SubComplaints !== null &&
                        props.SubComplaints.length > 0 &&
                        props.SubComplaints[selectedComplaintIndex].itemsList === true) ?
                        props.SelectedItemsList && props.SelectedItemsList !== null && props.SelectedItemsList.length > 0 &&
                        props.submititems === true &&
                        (props.SubComplaints[selectedComplaintIndex].imageUpload &&
                            props.SubComplaints[selectedComplaintIndex].imageUpload === true ?
                            ((props.SelectedItemsImage &&
                                props.SelectedItemsImage !== null) ?
                                <button
                                    className={classes.submitbutton}
                                    onClick={() => { setRegisterComplaintOpen(false); setSelectOptionComplaintOpen(true); setOpen(false) }}>
                                    {'Submit'}
                                </button>
                                : null
                            )
                            : null
                        )
                        :
                        ((props.issubcomplaintslist &&
                            props.issubcomplaintslist === true &&
                            props.SubComplaints && props.SubComplaints !== null &&
                            props.SubComplaints.length > 0 &&
                            props.SubComplaints[selectedComplaintIndex].imageUpload &&
                            props.SubComplaints[selectedComplaintIndex].imageUpload === true) ?
                            ((props.SelectedItemsImage &&
                                props.SelectedItemsImage !== null) ?
                                <button
                                    className={classes.submitbutton}
                                    onClick={() => { setRegisterComplaintOpen(false); setSelectOptionComplaintOpen(true); setOpen(false) }}>
                                    {'Submit'}
                                </button>
                                : null)
                            : null)
                    }
                    {(props.issubcomplaintslist &&
                        props.issubcomplaintslist === true &&
                        props.SubComplaints && props.SubComplaints !== null &&
                        props.SubComplaints.length > 0 &&
                        props.SubComplaints[selectedComplaintIndex].imageUpload === false &&
                        props.SubComplaints[selectedComplaintIndex].itemsList === false) &&
                        <>
                            <SelectOption
                                refund={props.SubComplaints && props.SubComplaints[selectedComplaintIndex] && props.SubComplaints[selectedComplaintIndex].refund}
                                replacement={props.SubComplaints && props.SubComplaints[selectedComplaintIndex] && props.SubComplaints[selectedComplaintIndex].replacement}
                                orderID={props.orderID}
                                {...props} />
                            {((props.SelectedOption && props.SelectedOption !== null &&
                                (props.SelectedOption.refund === true || props.SelectedOption.replacement === true)) ?
                                <button
                                    onClick={() => {
                                        props.RegisterComplaint(
                                            props.orderID && props.orderID,
                                            localStorage.getItem('userid'),
                                            props.OrderDetails && props.OrderDetails.storeid && props.OrderDetails.storeid.id,
                                            selectedcomplaints,
                                            props.SelectedItemsImage && props.SelectedItemsImage,
                                            props.SelectedItemsList && props.SelectedItemsList,
                                            props.SelectedOption && props.SelectedOption.refund,
                                            0,
                                            's',
                                            props.SelectedOption && props.SelectedOption.replacement,
                                            ''
                                        );
                                        setSelectOptionComplaintOpen(false);
                                        setOpen(false);
                                        setRegisterComplaintOpen(false);
                                    }}
                                    className={classes.submitbutton}>
                                    {'Register Complaint'}
                                </button>
                                :
                                null)}
                        </>
                    }
                    {(props.issubcomplaintslist &&
                        props.issubcomplaintslist === true &&
                        props.SubComplaints && props.SubComplaints !== null &&
                        props.SubComplaints.length > 0 &&
                        props.SubComplaints[selectedComplaintIndex].imageUpload === false &&
                        props.SubComplaints[selectedComplaintIndex].itemsList === false) &&
                        (props.SubComplaints[selectedComplaintIndex].refund === false && props.SubComplaints[selectedComplaintIndex].replacement === false)
                        ?
                        <button
                            onClick={() => {
                                props.RegisterComplaint(
                                    props.orderID && props.orderID,
                                    localStorage.getItem('userid'),
                                    props.OrderDetails && props.OrderDetails.storeid && props.OrderDetails.storeid.id,
                                    selectedcomplaints,
                                    props.SelectedItemsImage && props.SelectedItemsImage,
                                    props.SelectedItemsList && props.SelectedItemsList,
                                    props.SelectedOption && props.SelectedOption.refund,
                                    0,
                                    's',
                                    props.SelectedOption && props.SelectedOption.replacement,
                                    ''
                                );
                                setSelectOptionComplaintOpen(false);
                                setOpen(false);
                                setRegisterComplaintOpen(false);
                            }}
                            className={classes.submitbutton}>
                            {'Register Complaint'}
                        </button>
                        : null
                    }
                </DialogContent>
            </Dialog>
            <Dialog
                open={selectoptionopen}
                PaperProps={{
                    style: {
                        borderRadius: '10px',
                        backgroundColor: '#fff',
                        width: '100%',
                    }
                }}
                maxWidth={'sm'}
                onClose={() => setSelectOptionComplaintOpen(false)}>
                <DialogTitle
                    className={classes.modalHeading}>
                    <span
                        className={classes.modalHeading}>
                        {"Select Option"}
                    </span>
                    <span
                        style={{
                            width: '25px',
                            float: 'right',
                            color: '#ffffff',
                            borderRadius: '50%',
                            fontSize: '14px',
                            height: '25px',
                            background: 'rgba(133, 190, 73, 1)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}>
                        <CloseIcon
                            style={{
                                cursor: 'pointer',
                                fontSize: '14px'
                            }}
                            onClick={() => setSelectOptionComplaintOpen(false)} />
                    </span>
                </DialogTitle>
                <DialogContent>
                    {props.issubcomplaintslist &&
                        props.issubcomplaintslist === true &&
                        props.SubComplaints && props.SubComplaints !== null &&
                        props.SubComplaints.length > 0 &&
                        ((props.SubComplaints[selectedComplaintIndex].refund === true || props.SubComplaints[selectedComplaintIndex].replacement === true) ?
                            props.SelectedOption && props.SelectedOption !== null && (props.SelectedOption.refund === true || props.SelectedOption.replacement === true) ?
                                (<>
                                    <SelectOption
                                        refund={props.SubComplaints[selectedComplaintIndex].refund}
                                        replacement={props.SubComplaints[selectedComplaintIndex].replacement}
                                        orderID={props.orderID}
                                        {...props} />
                                    <button
                                        onClick={() => {
                                            props.RegisterComplaint(
                                                props.orderID && props.orderID,
                                                localStorage.getItem('userid'),
                                                props.OrderDetails && props.OrderDetails.storeid && props.OrderDetails.storeid.id,
                                                selectedcomplaints,
                                                props.SelectedItemsImage && props.SelectedItemsImage,
                                                props.SelectedItemsList && props.SelectedItemsList,
                                                props.SelectedOption && props.SelectedOption.refund,
                                                0,
                                                's',
                                                props.SelectedOption && props.SelectedOption.replacement,
                                                ''
                                            ); setSelectOptionComplaintOpen(false);
                                        }}
                                        className={classes.submitbutton}>
                                        {'Register Complaint'}
                                    </button>
                                </>)
                                :
                                <SelectOption
                                    refund={props.SubComplaints[selectedComplaintIndex].refund}
                                    replacement={props.SubComplaints[selectedComplaintIndex].replacement}
                                    orderID={props.orderID}
                                    {...props} />
                            :
                            <>
                                <SelectOption
                                    refund={props.SubComplaints[selectedComplaintIndex].refund}
                                    replacement={props.SubComplaints[selectedComplaintIndex].replacement}
                                    orderID={props.orderID}
                                    {...props} />
                                <button
                                    className={classes.submitbutton}
                                    onClick={() => {
                                        props.RegisterComplaint(
                                            props.orderID && props.orderID,
                                            localStorage.getItem('userid'),
                                            props.OrderDetails && props.OrderDetails.storeid && props.OrderDetails.storeid.id,
                                            selectedcomplaints,
                                            props.SelectedItemsImage && props.SelectedItemsImage,
                                            props.SelectedItemsList && props.SelectedItemsList,
                                            props.SelectedOption && props.SelectedOption.refund,
                                            0,
                                            's',
                                            props.SelectedOption && props.SelectedOption.replacement,
                                            ''
                                        ); setSelectOptionComplaintOpen(false);
                                    }}
                                >
                                    {'Register Complaint'}
                                </button>
                            </>
                        )
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ComplaintsList