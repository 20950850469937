import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    root: {
        paddingBottom:'35px'
    },
   departmentname: {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '25px',
        position:'relative',
        paddingBottom:'10px',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        overflow:'hidden',
        width:'100%',
        alignItems:'center',
        display:'flex',
    },
    viewallgrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'right',
        textAlign:'right',
        paddingRight:'20px',
    },
    viewall: {
        width: '65px',
        height:'22px',
        background: 'rgba(196, 196, 196, 0.2)',
        borderRadius: '15.5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.5)',
        fontSize: '12px',
        cursor: 'pointer',
        textDecoration:'none',
        fontWeight: 600,
    },
    link: {
        textDecoration:'none',
    },
    text: {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 800,
        lineHeight: '25px',
        position:'relative',
        paddingBottom:'10px',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis',
        width:'100%'
    },
}));